import './App.css';
import {useState} from "react";
import Confetti from 'react-confetti';
import {useWindowSize} from 'react-use';

function App() {
  const [numNo, setNumNo] = useState(0);
  const [yesPressed, setYesPressed] = useState(false);
  const {width, height} = useWindowSize()

  const ARR_LABELS = [
    "No",
    "U sure?",
    "Try again",
    "<- There",
    ".-.",
    "🥺",
    "pls pls pls",
    "Last chance",
    "Very Last chance",
    "Lastest chance",
    "Yes"
  ];

  function yesClicked() {
    console.log("yee");
    setYesPressed(true)
  }

  function noClicked() {
    setNumNo(numNo + 1)
  }

  return (
    <div className="App">
      {!yesPressed && <div className="main-container">
        <img src={require('./assets/cat_heart.png')} className="central-img" alt="img"/>
        <span>Will u be my valentine?</span>
        <div className="button-container">
          <button className="button-yes" onClick={yesClicked}><span>Yes</span></button>
          <button className={`button-${numNo === ARR_LABELS.length - 1 ? "yes" : "no"}`}
                  onClick={numNo === ARR_LABELS.length - 1 ? yesClicked : noClicked}>
            <span>{ARR_LABELS[numNo]}</span></button>
        </div>
      </div>}
      {yesPressed &&
        <div className="main-container">
          <Confetti
            width={width}
            height={height}
          />
          <img src={require('./assets/cat.gif')} className="central-img-success" alt="img"/>
          <span>I love u ❤️</span>
        </div>}
    </div>
  );
}

export default App;
